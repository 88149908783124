import { Fragment, FC } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { HeaderStyled } from './styles';

const Hero: FC = () => {
  const {
    allMdx: { nodes },
  } = useStaticQuery(query);

  return (
    <Fragment>
      {nodes && (
        <HeaderStyled>
          <article>
            <h1>{nodes[0].frontmatter.name}</h1>
            <h2>{nodes[0].frontmatter.bio}</h2>
            <a href={`mailto:${nodes[0].frontmatter.email}`}>Contact Me</a>
          </article>
        </HeaderStyled>
      )}
    </Fragment>
  );
};

export default Hero;

export const query = graphql`
  query {
    allMdx(filter: { fileAbsolutePath: { regex: "/(home)/" } }) {
      nodes {
        frontmatter {
          name
          bio
          email
        }
        id
      }
    }
  }
`;
