import styled from '@emotion/styled';
import HomeHero from '../../images/bg-home.jpg';

export const HeaderStyled = styled.header`
  background: #0a0b0c no-repeat center center;
  background-size: cover;
  background-image: url('${HomeHero}');
  padding: 10.5rem 0 7rem;
  position: relative;
  article {
    backdrop-filter: blur(0.125rem);
    background: rgba(9, 18, 37, 0.4);
    border-radius: 1.125rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 4rem 1rem;
    margin: 0 1rem 3rem;
    h1,
    h2 {
      color: #fff;
      text-align: center;
    }
    h1 {
      font-size: 2rem;
      font-weight: 600;
    }
    h2 {
      font-size: 1.5rem;
      font-weight: 400;
    }
    a {
      border-radius: 0.375rem;
      border: none;
      color: #fff;
      cursor: pointer;
      display: block;
      font-size: 1rem;
      margin: 0 auto;
      outline: none;
      padding: 15px 40px;
      position: relative;
      text-decoration: none;
      width: fit-content;
      z-index: 0;
      &::after {
        content: '';
        z-index: -1;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #333;
        left: 0;
        top: 0;
        border-radius: 10px;
      }
      &::before {
        content: '';
        background: linear-gradient(
          45deg,
          #ff0000,
          #ff7300,
          #fffb00,
          #48ff00,
          #00ffd5,
          #002bff,
          #ff00c8,
          #ff0000
        );
        position: absolute;
        top: -2px;
        left: -2px;
        background-size: 600%;
        z-index: -1;
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        filter: blur(8px);
        animation: glowing 20s linear infinite;
        transition: opacity 0.3s ease-in-out;
        border-radius: 10px;
        opacity: 0;
      }
      &:hover::before {
        opacity: 1;
      }
    }
    img {
      position: absolute;
    }
    @keyframes glowing {
      0% {
        background-position: 0 0;
      }
      50% {
        background-position: 400% 0;
      }
      100% {
        background-position: 0 0;
      }
    }
  }
  @media screen and (orientation: portrait) {
    align-items: center;
    display: flex;
    height: 100vh;
    justify-content: center;
    padding: 0;
  }
  @media (min-width: 40rem) {
    padding: 14rem 0 10.5rem;
    article {
      gap: 1rem;
      margin: 0 auto;
      max-width: 35rem;
      padding: 2rem 2rem;
      h1 {
        font-size: 3rem;
      }
      h2 {
        font-size: 2rem;
      }
      a {
        font-size: 1.25rem;
      }
    }
  }
`;
